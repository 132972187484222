:root {
    --toolbar-height: 48px;
}

.outerContainer {
    display: flex;
    flex-grow: 1;
    height: 2.75rem;
    position: relative;
    z-index: var(--z-index-one);
}

.innerContainer {
    display: flex;
    align-items: center;
    height: 100%;
    cursor: pointer;
}

.modalContainer {
    margin: auto;
    width: 18.75rem;
}

.logoContainer {
    height: 90%;
    flex-grow: 1;
    display: flex;
}

.logo {
    height: 90%;
    padding: 0.2rem;
}

.logoImage {
    height: 100%;
    width: 100%;
}

.logoTextContainer {
    padding-top: 0.2rem;
    padding-left: 1rem;
}

.outerContainer {
    display: flex;
    justify-content: center;
    position: relative;
    top: 5rem;
    left: calc(50% - 0.3rem);
    transform: translate(-50%, -50%);
}

.innerContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.logoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 6.25rem;
    width: 6.25rem;
    border-radius: 50%;
    border: 0.063rem solid var(--color-purple-logo);
    background-image: url('../../assets/loaderBackground.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    object-fit: cover;
}

.logoImage {
    height: 5rem;
}

.logoTextContainer {
    padding-top: 0.3rem;
}

.targetIconContainer {
    padding-left: 1rem;
    display: flex;
    align-items: center;
    justify-content: start;
    color: var(--color-red);
}

.probability {
    padding-left: 0.5rem;
}

.logoBox {
    --logo-box-width: var(--logo-box-default-width);

    display: flex;
    justify-content: center;
    align-items: center;
    height: var(--logo-box-default-height);
    width: var(--logo-box-width);
    background-color: var(--color-action-drawer-mobile);
    clip-path: polygon(0 0, 100% 0%, 80% 100%, 0% 100%);
    box-shadow: 5px 0 10px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease;
    border-bottom: var(--border-three-px-ridge-purple);
    border-top: var(--border-three-px-ridge-purple);
}

.line {
    --line-right: 0.344rem;
    --line-rotation: 12.4deg;

    position: absolute;
    height: var(--logo-box-default-height);
    border-right: var(--border-three-px-ridge-purple);
    right: var(--line-right);
    top: 0;
    transform: rotate(var(--line-rotation));
}

.logo {
    --logo-padding-left: 0;

    padding-left: var(--logo-padding-left);
    height: 60%;
}

.menuIcon {
    font-size: 1.5rem;
    color: var(--color-purple-logo);
}

.isAlbum {
    --logo-box-width: var(--logo-box-album-width);
    --line-right: 7px;
    --line-rotation: 16deg;
}

.isIosAlbum {
    --logo-padding-left: 1rem;
}

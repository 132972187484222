.logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.container {
    border: var(--border-three-px-ridge-purple);
    background-color: var(--color-purple-very-dark-transparent);
    z-index: var(--z-index-three);
    padding: 2rem 2rem 0;
}

.container a {
    color: var(--color-turquoise)
}

.title {
    display: flex;
    justify-content: center;
}

.lastUpdated {
    display: flex;
    justify-content: flex-end;
}

.body {
    margin-top: 2rem;
}

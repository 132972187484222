:root {
    --logo-box-default-width: 55px;
    --logo-box-album-width: calc(var(--logo-box-default-width) + 1rem);
    --logo-box-default-height: 50px;
    --main-menu-portrait-margin-top: 2rem;
}

.menuMarginTop {
    --menu-margin-top: 0;

    margin-top: var(--menu-margin-top);
}

.isPortraitMenu {
    --menu-margin-top: var(--main-menu-portrait-margin-top);
}

.container {
    position: fixed;
    z-index: var(--z-index-mobile-header);
    composes: menuMarginTop;
}

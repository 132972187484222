.button {
    --button-padding: 0.2rem 1rem 0.2rem 0.2rem;
    --button-width: 100%;
    --button-height: 2.25rem;

    margin-right: 0.11rem;
    cursor: pointer;
    display: flex;
    padding: var(--button-padding);
    align-items: center;
    border-radius: 0.2rem;
    width: var(--button-width);
    height: var(--button-height);
}

.isIconOnly {
    --button-padding: 0.2rem;
    --button-width: var(--button-height);
}

.button .icon {
    border: var(--border-one-px-solid-white);
    font-size: calc(var(--button-height));
    padding: 0.1rem;
}

.textContainer {
    padding-left: 0.5rem;
}

.container {
    --container-position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: var(--container-position);
}

.isMobile {
    --container-position: absolute;
}

.image {
    --image-opacity: 1;
    width: 75%;
    opacity: var(--image-opacity);
}

.noCards {
    --image-opacity: 0.6;
}

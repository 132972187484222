:root {
    --z-index-drawing-card: 2900;
    --z-index-mobile-action-drawer: 1500;
    --z-index-pulsating-card: 2750;
    --z-index-moving-card: 2850;
    --z-index-one: 1;
    --z-index-three: 3;
    --z-index-modal: 3000;
    --z-index-log-journal: 2840;
    --z-index-room-widgets: 1600;
    --z-index-mobile-header: 5;
}

.drawer.drawer {
    --drawer-width: 35%;

    width: var(--drawer-width);
    background-color: var(--color-action-drawer-mobile);
    background-image: none;
    overflow: visible;
}

.menuList.menuList {
    border-top: var(--border-three-px-ridge-purple);
    overflow: auto
}

.gameItem.gameItem {
    flex-grow: 0.1
}

.subItem.subItem {
    padding-left: 2.5rem
}

.subItemFlex.subItemFlex {
    display: flex;
    justify-content: space-evenly
}

.listItemIcon.listItemIcon {
    min-width: 35px
}

.logoBox {
    --logo-box-width: var(--logo-box-default-width);

    position: absolute;
    right: calc(0px - var(--logo-box-width));
}

.line {
    --line-top: calc(var(--logo-box-default-height) - 0.5px);

    position: absolute;
    height: 100%;
    border-right: var(--border-three-px-ridge-purple);
    right: -3px;
    top: var(--line-top);
}

.isPortrait.isPortrait {
    --drawer-width: 65%;
}

.divider {
    padding: 1rem
}

.modal {
    width: 90%
}

.donationWidgets {
    display: flex;
    justify-content: center
}

.isAlbum {
    --logo-box-width: var(--logo-box-album-width);
}

.button {
    --button-width: 100%;
    --button-height: 2.25rem;
    --icon-backgroubd-color: var(--color-white);

    margin-left: 0.11rem;
    margin-right: 0.11rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 0.2rem;
    width: var(--button-width);
    height: var(--button-height);

    &:hover {
        --icon-backgroubd-color: var(--color-transparent);
    }
}

.isIconOnly {
    --button-width: var(--button-height);
}

.iconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--button-height);
    height: var(--button-height);
    background-color: var(--icon-backgroubd-color);
}

.icon {
    font-size: calc(var(--button-height) * 0.7);
}

.textContainer {
    padding-left: 0.5rem;
}

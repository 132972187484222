:root {
    --widget-font-size: 1.563rem;
    --widget-font-size-mobile: 1.5rem;
    --tooltip-font-size: 0.7rem;
    --button-font-size: 0.8rem;
    --large-card-top-bar-font-size: 1rem;
    --large-card-name-font-size: 1.1rem;
    --large-card-details-font-size: 1rem;
    --small-card-top-bar-font-size: 1rem;
    --small-card-name-font-size: 0.7rem;
}

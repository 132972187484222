.container {
    display: flex;
    justify-content: center;
    gap: 1rem;
    padding-top: 1rem;
    padding-bottom: 2rem;
    z-index: var(--z-index-one);
    opacity: 0;
    animation: fadeIn 0.5s ease-in-out forwards;
    animation-delay: 2s;
}

 @keyframes fadeIn {
      from {
        opacity: 0;
        transform: translateY(-2rem);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }

.link {
    color: var(--color-white);
    cursor: pointer;
}

.link:hover {
    color: var(--color-turquoise);
    text-decoration: underline;
}

.title {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 1rem;
}

.title .icon {
    font-size: 2.5rem;
    padding: 0.1rem;
    border: var(--border-one-px-solid-white);
}

.text {
    padding-left: 0.5rem;
    font-size: 1rem;
}

.input .input {
    display: flex;
    justify-content: center;
}

.buttonBox {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.button {
    padding-inline: 1.5rem;
    width: 11.24rem;
    height: 2rem;
}

.container {
    --image-height: 100%;

    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: var(--image-height);
    background-color: var(--color-grey-transparent);
    opacity: 0.8;

    &:after {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: var(--image-height);
        content: '';
        background-image: url('../../assets/quadraen_background_animation.png');
        background-size: cover;
        filter: blur(0.1rem);
        background-position: center;
        animation: lightning 30s linear infinite;
    }
}

@keyframes lightning {
    0% {
        opacity: 0;
    }
    20% {
        opacity: 0;
    }
    21% {
        opacity: 0.18;
    }
    21.0001% {
        opacity: 0;
    }
    22% {
        opacity: 0;
    }
    23% {
        opacity: 0.26;
    }
    23.0001% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

:root {
    --color-purple-very-dark-transparent: #02001899;
    --color-purple-very-dark: #020018;
    --color-white: #ffffff;
    --color-white-transparent: rgba(255, 255, 255, 0.12);
    --color-red: #ff0000;
    --color-purple: #9d53fb;
    --color-action-drawer-mobile: #0B0B1CCC;
    --color-turquoise: #6DEAD6;
    --color-scarlet: #D62727;
    --color-yellow: #F09000;
    --color-player-background-transparent: rgba(87, 62, 175, 0.3);
    --color-transparent: #FFFFFF00;
    --color-grey: #868282;
    --color-grey-transparent: rgba(0, 0, 0, 0.7);
    --color-green: #028A0F;
    --color-transparent-black: #0000004C;
    --color-essence: #58219e;
    --color-essence-transparent: #58219e99;
    --color-blue: #051d7a;
    --color-blue-transparent: #051d7a99;
    --color-discord: #5865F2;
    --color-discord-transparent: #6773EAFF;
    --color-card-nebula: #3D1DC9;
    --color-card-capsule: #157011;
    --color-card-radiance: #6A1CAE;
    --color-card-arsenal: #C9511D;
    --color-card-role: #474833;
    --color-google-text-backgroun: #202124;
    --color-purple-logo: #F257FF;
}

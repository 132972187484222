@import "css/variables/variables.css";

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
    font-family: 'cousine';
    src: local('cousine'), url('./fonts/Cousine-Regular.ttf') format("truetype");
}

@font-face {
    font-family: 'cousine-bold';
    src: local('cousine-bold'), url('./fonts/Cousine-Bold.ttf') format("truetype");
}

@font-face {
    font-family: 'cousine-bold-italic';
    src: local('cousinebold-italic'), url('./fonts/Cousine-BoldItalic.ttf') format("truetype");
}

@font-face {
    font-family: 'cousine-italic';
    src: local('cousine-italic'), url('./fonts/Cousine-Italic.ttf') format("truetype");
}

@font-face {
    font-family: 'orbitron-black';
    src: local('orbitron-black'), url('./fonts/Orbitron-Black.ttf') format("truetype");
}

@font-face {
    font-family: 'orbitron-bold';
    src: local('orbitron-bold'), url('./fonts/Orbitron-Bold.ttf') format("truetype");
}

@font-face {
    font-family: 'orbitron-extrabold';
    src: local('orbitron-extrabold'), url('./fonts/Orbitron-ExtraBold.ttf') format("truetype");
}

@font-face {
    font-family: 'orbitron-medium';
    src: local('orbitron-medium'), url('./fonts/Orbitron-Medium.ttf') format("truetype");
}

@font-face {
    font-family: 'orbitron-regular';
    src: local('orbitron-regular'), url('./fonts/Orbitron-Regular.ttf') format("truetype");
}

@font-face {
    font-family: 'orbitron-semibold';
    src: local('orbitron-semibold'), url('./fonts/Orbitron-SemiBold.ttf') format("truetype");
}

@font-face {
    font-family: 'resrics';
    src: local('resrics'), url('./fonts/Resrics.otf') format("truetype");
}

.panel.panel {
    --panel-top: 0.1rem;

    top: var(--panel-top);
    position: fixed;
    right: 10px;
    z-index: var(--z-index-mobile-header);
}

.isPortrait.isPortrait {
    --panel-top: var(--main-menu-portrait-margin-top);
}

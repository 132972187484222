@media (max-width: 599px) {
    :root {
        --widget-font-size: 0.938rem;
        --tooltip-font-size: 0.3rem;
        --large-card-top-bar-font-size: min(0.5rem, 1.45vh, 1.45vw);
        --large-card-name-font-size: min(0.6rem, 1.6vh, 1.6vw);
        --large-card-details-font-size: min(0.5rem, 1.4vh, 1.4vw);
        --small-card-top-bar-font-size: min(1rem, 1.4vh, 1.4vw);
        --small-card-name-font-size: min(0.35rem, 1.1vh, 1.1vw);
    }
}

@media (min-width: 600px) {
    :root {
        --open-card-bottom-padding: 1.2rem;
        --widget-font-size: 1.3rem;
        --tooltip-font-size: 0.5rem;
        --large-card-top-bar-font-size: min(0.65rem, 1.55vh, 1.55vw);
        --large-card-name-font-size: min(0.7rem, 1.65vh, 1.65vw);
        --large-card-details-font-size: min(0.5rem, 1.4vh, 1.4vw);
        --small-card-top-bar-font-size: min(1rem, 1.4vh, 1.4vw);
        --small-card-name-font-size: min(0.5rem, 1.1vh, 1.1vw);
    }
}

@media (min-width: 900px) {
    :root {
        --rule-book-widget-border-radius: 0.2rem;
        --open-card-bottom-padding: 1.4rem;
        --widget-font-size: 1.563rem;
        --tooltip-font-size: 0.7rem;
        --large-card-top-bar-font-size: min(0.8rem, 1.65vh, 1.65vw);
        --large-card-name-font-size: min(0.85rem, 1.85vh, 1.85vw);
        --large-card-details-font-size: min(0.7rem, 1.5vh, 1.5vw);
        --small-card-top-bar-font-size: min(1rem, 1.4vh, 1.4vw);
        --small-card-name-font-size: min(0.6rem, 1.15vh, 1.15vw);
    }
}

@media (min-width: 1200px) {
    :root {
        --open-card-bottom-padding: 1.6rem;
        --widget-font-size: 1.875rem;
        --tooltip-font-size: 0.8rem;
        --large-card-top-bar-font-size: min(1.1rem, 1.75vh, 1.75vw);
        --large-card-name-font-size: min(1.1rem, 2vh, 2vw);
        --large-card-details-font-size: min(1rem, 1.6vh, 1.6vw);
        --small-card-top-bar-font-size: min(1rem, 1.4vh, 1.4vw);
        --small-card-name-font-size: min(0.7rem, 1.15vh, 1.15vw);
    }
}

@media (min-width: 1900px) {
    :root {
        --open-card-bottom-padding: 1.8rem;
        --large-card-top-bar-font-size: min(1.2rem, 1.9vh, 1.9vw);
        --large-card-name-font-size: min(1.25rem, 2.1vh, 2.1vw);
        --large-card-details-font-size: min(1.125rem, 1.75vh, 1.75vw);
        --small-card-top-bar-font-size: min(0.625rem, 1.1vh, 1.1vw);
        --small-card-name-font-size: min(0.8rem, 1.25vh, 1.25vw);
    }
}

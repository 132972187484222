.cardOuterContainer {
    width: 100%;
    height: 100%;
}

.cardContainer {
    --card-type-color: var(--color-card-role);
    --dimension: var(--card-dimension);
    --card-name-font-size: var(--small-card-name-font-size);
    --card-name-text-align: center;
    --texture-image-width: 100%;
    --small-card-bottom-bar-modifier: 0.18;
    --small-card-bottom-bar-wrap-state: nowrap;

    height: 100%;
    width: 100%;
    display: flex;
}

.cardBackground {
    width: 100%;
    height: 100%;
    background-color: var(--card-type-color);
    border-radius: 0.625rem;
}

.backgroundTexture {
    mix-blend-mode: multiply;
}

.backgroundTextureImage {
    width: var(--texture-image-width);
    height: 100%;
    object-fit: cover;
}

.backgroundFrame {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.largeTopBar {
    position: absolute;
    z-index: var(--z-index-one);
    margin: 0.438rem;
    border: 0.188rem solid var(--card-type-color);
    width: calc(var(--dimension) - 0.875rem);
}

.smallTopBar {
    position: absolute;
    z-index: var(--z-index-one);
    margin: 0.188rem;
    border: 0.1rem solid var(--card-type-color);
    width: calc(var(--dimension) - 0.875rem);
}

.openLargeCardContainer {
    position: absolute;
    top: calc(var(--dimension) * 0.08);
    width: var(--dimension);
    height: var(--dimension);
    display: flex;
    justify-content: center;
    align-items: center;
}

.openSmallCardContainer {
    position: absolute;
    width: 100%;
    height: calc(var(--dimension) * 0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.cardContainerShowFullSizeImage {
    height: 100%;
}

.openSmallCardPicture {
    position: absolute;
    padding: 0.1rem;
    max-height: calc(var(--dimension) * 0.75);
    max-width: var(--dimension);
}

.cardPictureShowFullSizeImage {
    max-height: 100%;
    max-width: 100%;
    padding: 0.25rem;
}

.openLargeCardPicture {
    position: absolute;
    padding: 0.6rem 0 var(--open-card-bottom-padding) 0;
    max-width: var(--dimension);
    max-height: var(--dimension);
}

.largeTopBarTextContainer {
    position: absolute;
    width: calc(var(--dimension) - 1.25rem);
    z-index: var(--z-index-one);
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100%;
}

.largeTopBarText {
    font-size: var(--large-card-top-bar-font-size);
    text-transform: uppercase;
    font-family: var(--font-family-orbitron), sans-serif;
    font-weight: bold;
    text-align: center;
    margin: 0;
    color: var(--card-type-color);
}

.largeTopBarBackground {
    height: calc(var(--dimension) * 0.08);
}

.smallTopBarBackground {
    height: calc(var(--dimension) * 0.12);
}

.largeBottomBar {
    position: absolute;
    bottom: 0;
    margin: 0.438rem;
    border: 0.188rem solid var(--card-type-color);
    width: calc(var(--dimension) - 0.875rem);
}

.smallBottomBar {
    position: absolute;
    bottom: 0;
    margin: 0.188rem;
    border: 0.1rem solid var(--card-type-color);
    width: calc(var(--dimension) - 0.376rem);
}

.largeBottomBarBackground {
    height: calc(var(--dimension) * 0.385);
}

.smallBottomBarBackground {
    height: calc(var(--dimension) * var(--small-card-bottom-bar-modifier));
}

.largeBottomBarTextContainer {
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0.5rem 1rem 0 1rem;
    gap: 0.4rem;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
    height: calc(var(--dimension) * 0.385);
}

.smallBottomBarTextContainer {
    position: absolute;
    padding: 0.2rem;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(var(--dimension) * var(--small-card-bottom-bar-modifier));
}

.bottomBarCardNameText {
    font-size: var(--card-name-font-size);
    font-family: var(--font-family-orbitron), sans-serif;
    font-weight: bold;
    margin: 0;
    white-space: var(--small-card-bottom-bar-wrap-state);
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: var(--card-name-text-align);
    color: var(--card-type-color);
}

.bottomBarCardDetailsText {
    font-size: var(--large-card-details-font-size);
    line-height: normal;
    margin: 0;
    text-align: left;
    color: rgba(26, 27, 37, 1);
}

.gradientContainer {
    position: absolute;
    width: 100%;
    height: 100%;
}

.gradientContainerBackground {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--card-type-color);
    filter: drop-shadow(0.125rem 0.25rem 0.25rem rgba(0, 0, 0, 0.5));
}

.gradientContainerBase {
    position: absolute;
    top: 0.063rem;
    left: 0.063rem;
    width: calc(100% - 0.063rem);
    height: calc(100% - 0.063rem);
    background-color: var(--color-white);
    opacity: 0.7;
    border: 0.063rem solid var(--card-type-color);
    box-shadow: 0 0 0 0.125rem rgba(0, 0, 0, 0.5);
}

.gradientOverlay {
    width: 100%;
    height: 100%;
    background: linear-gradient(
        290deg,
        #363F41 0%,
        #3D4547 16%,
        var(--color-white) 28%,
        #E4E4E4 36%,
        #394244 47%,
        #3D4547 64%,
        #F5F5F5 70%,
        #373E40 86%,
        var(--color-white) 100%
    );
    mix-blend-mode: overlay;
    opacity: 0.5;
}

.Nebula {
    --card-type-color: var(--color-card-nebula);
}

.Capsule {
    --card-type-color: var(--color-card-capsule);
}

.Role {
    --card-type-color: var(--color-card-role);
}

.Radiance {
    --card-type-color: var(--color-card-radiance);
}

.Arsenal {
    --card-type-color: var(--color-card-arsenal);
}

.isInactive {
    filter: grayscale(80%);
    opacity: 0.9;
    background-color: var(--color-white-transparent);
}

.isSelected {
    outline: 0.2rem solid var(--color-yellow);
}

.isMobile {
    --large-card-top-bar-font-size: 0.75rem;
    --large-card-name-font-size: 0.8rem;
    --large-card-details-font-size: 0.7rem;
    --small-card-name-font-size: 0.65rem;
}

.ruleBookWidget {
    position: absolute;
    z-index: var(--z-index-one);
    top: 0;
    right: 0;
    background-color: var(--card-type-color);
    border-radius: 50%;
    border: var(--rule-book-widget-border-radius) solid var(--color-white);
}

.tinyCard.tinyCard {
    --texture-image-width: 900%;
}

.smallCard {
    --dimension: var(--card-dimension);
    --texture-image-width: 600%;

    @media (hover: hover) {
        &:hover {
            --small-card-bottom-bar-modifier: 0.42;
            --small-card-bottom-bar-wrap-state: wrap;
            --card-name-font-size: calc(var(--small-card-name-font-size) * 1.15);
        }
    }
}

.largeCard {
    --dimension: var(--large-card-dimension);
    --card-name-font-size: var(--large-card-name-font-size);
    --card-name-text-align: left;
    --texture-image-width: 300%;
}

.backCoverImage {
    width: 100%;
    height: 100%;
}

.logoText {
    font-family: var(--font-family-resrics), sans-serif;
    font-size: 1.2rem;
    margin: auto;
}

.white {
    color: var(--color-white);
}

.purple {
    color: var(--color-purple-logo);
}

.widgets {
    --widgets-position-side: 10px;
    --widgets-right: none;
    --widgets-left: none;

    position: fixed;
    display: flex;
    right: var(--widgets-right);
    left: var(--widgets-left);
    top: var(--toolbar-height);
    z-index: var(--z-index-one);
}

.isPositionedLeft {
    --widgets-left: var(--widgets-position-side);
}

.isPositionedRight {
    --widgets-right: var(--widgets-position-side);
}
